import React, { memo, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import 'moment/locale/fr';
import { useTranslation } from 'react-i18next';
import { Availability } from "../../types";
import { bookingsStore } from "../../stores/bookings.store";
import { Tag } from "../tag/tag";

interface IProps {
  onSelectedHour: any;
}

const TagsContent = styled.div`
  width: 100%;
`;

const TagsList = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 4px 0;
`;

const TagsContentDef = (props: IProps) => {
  const { t } = useTranslation();
  var availabilities: Array<Availability> = bookingsStore.getAvailabilities()
    .sort((first: Availability, second: Availability) => first.date > second.date ? 1 : -1);
  availabilities.forEach((availability: Availability) => {
    availability.hours.sort();
  });
  const [selectedDay, setSelectedDay] = useState(availabilities[0].date);
  const [selectedHour, setSelectedHour] = useState("");

  const handleOnClickDay = (ev: any) => {
    setSelectedDay(ev.target.dataset.value);
  }

  const handleOnClickHour = (ev: any) => {
    const hour = ev.target.dataset.value;
    const bookingTime = moment(selectedDay).add(hour.substring(0,2), "hours").add(hour.substring(2,4), "minutes").toDate();
    setSelectedHour(hour);
    props.onSelectedHour(bookingTime);
  }

  const dayTags = availabilities.map((availability: Availability) => {
    return <Tag key={availability.date}
                value={availability.date}
                tagText={moment(availability.date).format("D-MM-YYYY")}
                isSelected={availability.date === selectedDay.toString()}
                onClick={handleOnClickDay}
                />;
  });


  const hoursOfSelectedDay = availabilities.filter((availability: Availability) => availability.date === selectedDay.toString())[0].hours;
  const hourTags = hoursOfSelectedDay.length === 0 ? t("form.no_hour") :
    hoursOfSelectedDay.map((hour: string) => {
      return <Tag key={hour}
                  value={hour}
                  tagText={hour}
                  isSelected={hour === selectedHour}
                  onClick={handleOnClickHour}
                  />;
  });

  return <TagsContent>
      <TagsList>{dayTags}</TagsList>
      <TagsList>{hourTags}</TagsList>
  </TagsContent>
};

export const Tags = memo(TagsContentDef);