export const config = {
  // baseUrl: process.env.REACT_APP_API_BASE_URL ?? "http://api.lvh.me:3000/invite_me/v1", //local
  // baseUrl: process.env.REACT_APP_API_BASE_URL ?? "https://api.guestonline.fr/invite_me/v1", //preprod
  baseUrl: process.env.REACT_APP_API_BASE_URL ?? "https://prod.aws.guestonline.fr/invite_me/v1", //prod
  token: process.env.REACT_APP_TOKEN,
  routes: {
    base: "",
 },
 default_language: "fr",
 color: {
   white: "#ffffff",
   red: "#dc2c39",
   black: "#030304"
 },
 since_date: ""
};
