import { tap, Observable } from "rxjs";
import { Booking, Partner } from "../types"
import { getBooking, putConfirm } from "../services/booking.service";

class BookingStore {
  public booking: Booking = {} as Booking;
  public partner: Partner = {} as Partner;

  public getPartnerName(): string {
    return this.partner.name ?? "";
  }

  public getInvitation(): Booking {
    return this.booking;
  }

  public getBooking(id: string, bookingToken: string): Observable<Booking> {
    return getBooking(id, bookingToken).pipe(
      tap((response: any) => {
        this.booking = response.booking;
        this.partner.name = response.partner_name;
      })
    );
  }

  public putConfirm(id: string, bookingToken: string): Observable<Booking> {
    return putConfirm(id, bookingToken).pipe(
      tap((response: any) => {
        this.booking.booking_state = response.booking_state;
      })
    );
  }
}
export const bookingStore = new BookingStore();