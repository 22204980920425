import React, { memo } from "react";
import styled from "styled-components";
import { config } from "../../config";
import header from '../../assets/images/logo.png';

const HeaderWrapper = styled.div`
  position: fixed;
  top: 0;
  width: 100%;

  img {
    display: flex;
    max-width: 280px;
    width: 140px;
    margin: 10px;
  }
  `;

const Banner = styled.div`
    background-color: ${config.color.red};
    color: ${config.color.white};
    font-size: 1.8em;
    font-weight: 700;
    text-align: left;
    padding: 10px;
`;

const HeaderDef = () => {

  return <HeaderWrapper data-test="header" >
    <img src={header} alt="header" />
    <Banner>OMNIVORE FOOD FESTIVAL</Banner>
  </HeaderWrapper>;
};

export const Header = memo(HeaderDef);