import React, { memo } from "react";
import styled from "styled-components";
import { useTranslation } from 'react-i18next';
import { config } from "../../config";

interface IProps {
  buttonText: string;
  params?: any;
  color?: string;
  backgroundColor?: string;
  size?: string;
  disabled?: boolean;
  onClick: any;
}

interface IButtonSubmitStyle {
  color?: string;
  backgroundcolor?: string;
  size?: string;
  disabled?: boolean;
}

const ButtonWrapper = styled.div`
  cursor: pointer;
  margin: 8px 16px;
`;

const ButtonSubmitStyle = styled.div<IButtonSubmitStyle>`
  color: ${props => props.color ? props.color : config.color.white};
  background-color: ${props => props.backgroundcolor ? props.backgroundcolor : config.color.red};
  padding: 4px 12px;
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: none;
  opacity: ${props => props.disabled ? .5 : 1};
`;

const ButtonSubmitDef = (props: IProps) => {
  const { t } = useTranslation();
  return <ButtonWrapper>
    <ButtonSubmitStyle color={props.color}
                       backgroundcolor={props.backgroundColor}
                       disabled={props.disabled}
                       onClick={props.onClick}>
      {t(`button.${props.buttonText}`)}
    </ButtonSubmitStyle>
  </ButtonWrapper>
};

export const ButtonSubmit = memo(ButtonSubmitDef);