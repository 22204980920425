import React, { memo, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Loader } from "../loader/loader";
import { BookingsContent } from "../bookings/bookings-content";
import { ErrorLayout } from "../error-layout/error-layout";
import styled from "styled-components";
import { initRequestParamsService } from "../../services/request-params.service";
import { bookingsStore } from "../../stores/bookings.store"
import { ApiError } from "../../services/api-errors";

const AuthenticatedLayoutWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 140px - 90px);
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
`;

const AuthenticatedLayoutDef = () => {
  const [sessionInitialized, setSessionInitialized] = useState(false);
  const [apiError, setApiError] = useState<ApiError>();
  var {token} = useParams<{token: string}>();

  initRequestParamsService(token);
  useEffect(() => {
    const subscription = bookingsStore.initAuthentification().subscribe(() => {
      setSessionInitialized(true);
    }, (e: ApiError) => {
      setApiError(e);
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  if (!sessionInitialized && !apiError) {
    return <Loader size="small"/>;
  }

  const content = apiError ?
    <ErrorLayout status={((apiError.response && apiError.response.status))}
                 code={(apiError.response && apiError.response.statusText)}
                 message={apiError.message}/>
   : <BookingsContent />

  return <AuthenticatedLayoutWrapper>
    {content}
  </AuthenticatedLayoutWrapper>
};

export const AuthenticatedLayout = memo(AuthenticatedLayoutDef);