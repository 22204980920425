import React, { memo, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Booking } from "../../types";
import { Loader } from "../loader/loader";
import { Invitation } from "../invitation/invitation";
import { ErrorLayout } from "../error-layout/error-layout";
import { bookingStore } from "../../stores/booking.store"
import { ApiError } from "../../services/api-errors";

const UnauthenticatedLayoutWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 140px - 90px);
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const UnauthenticatedLayoutDef = () => {
  const [requestingData, setRequestingData] = useState(true);
  const [apiError, setApiError] = useState<ApiError>();
  const { id } = useParams<{ id: string}>();
  const bookingToken: string = document.location.search;

  useEffect(() => {
    bookingStore.getBooking(id, bookingToken).subscribe((response: Booking) => {
      setRequestingData(false);
    }, (e: ApiError) => {
      setApiError(e);
    });
  }, [id, bookingToken]);

  if (requestingData && !apiError) {
    return <Loader />;
  };

  const content = apiError ?
    <ErrorLayout status={((apiError.response && apiError.response.status))}
                 code={(apiError.response && apiError.response.statusText)}
                 message={apiError.message}/>
   : <Invitation />

  return <UnauthenticatedLayoutWrapper>
    {content}
  </UnauthenticatedLayoutWrapper>
};

export const UnauthenticatedLayout = memo(UnauthenticatedLayoutDef);