import { tap, Observable } from "rxjs";
import { Bookings as BookingList, Booking, BookingState, Partner, Availability, Availabilities } from "../types"
import { getAuthentification, getBookingsList, getAvailabilities } from "../services/bookings.service";

class BookingsStore {
  public bookings: BookingList = {} as BookingList;
  public availabilities: Availabilities = {} as Availabilities;
  public partner: Partner = {} as Partner;

  public getPartnerName(): string {
    return this.partner.name ?? "";
  }

  public getIfLeftHours(): boolean {
    return (Object.keys(this.bookings).length !== 0 &&
      Object(this.bookings).filter((booking: Booking) => booking.booking_state === BookingState.waiting).length > 0);
  }

  public getAvailableDays(): Array<Date> {
    var availableDays: any = []
    if (Object.keys(this.bookings).length !== 0) {
      availableDays = [...Array.from(new Set(this.bookings.map(booking => booking.booking_date)))]
    }
    return availableDays.sort();
  }

  public getAvailableHours(day: Date): any {
    var availableHours: any = [];
    if (Object.keys(this.bookings).length !== 0) {
      const bookings = Object(this.bookings).filter((booking: Booking) => booking.booking_date.toString() === day.toString());
      availableHours = [...Array.from(new Set(bookings.map((booking: Booking) => booking.booking_time)))];
      }
    return availableHours.sort();
  }

  public getBookingsOfTime(time: Date, booking_state: BookingState): any {
    var bookings: any = [];
    if (Object.keys(this.bookings).length !== 0) {
      bookings = Object(this.bookings).filter((booking: Booking) =>
      booking.booking_time === time
      && booking.booking_state === booking_state);
    }
    return bookings;
  }

  public getAvailabilities(): Array<Availability> {
    return Object.values(this.availabilities).flat();
  }

  public initAuthentification(): Observable<BookingList> {
    return getAuthentification().pipe(
      tap((response: any) => {
        this.bookings = response.bookings;
      })
    );
  }

    public getBookings(): Observable<BookingList> {
      return getBookingsList().pipe(
        tap((response: any) => {
          this.bookings = response.bookings;
          this.partner.name = response.partner_name;
        })
      );
    }

    public initAvailabilities(): Observable<Availabilities> {
      return getAvailabilities().pipe(
        tap((response: any) => {
          this.availabilities = response;
        })
      );
    }
}
export const bookingsStore = new BookingsStore();