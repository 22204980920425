import { Observable } from "rxjs";
import HttpService from "./http.service";
import { config } from "../config";

export const getAuthentification = (): Observable<any> => {
  return HttpService.get("bookings/authenticate", true);
};

export const getBookingsList = (): Observable<any> => {
  var queryParams = [];
  if (config.since_date !== '')  {
    queryParams.push({
      queryParamName: "since_date",
      queryParamValue: config.since_date
    });
  }
  const endPoint = HttpService.setQueryParams("bookings", queryParams);

  return HttpService.get(endPoint, true);
};

export const getAvailabilities = (): Observable<any> => {
  return HttpService.get("bookings/availabilities", true);
};
