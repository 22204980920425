import React, { memo, useState, useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from 'react-i18next';
import { config } from "../../config";
import { PartnerName } from "../partner/partnerName";
import { ButtonLink } from "../buttons/button-link";
import { Loader } from "../loader/loader";
import { Day } from "./day";
import { bookingsStore } from "../../stores/bookings.store";
import { ApiError } from "../../services/api-errors";

interface IButtonStyle{
  disabled: boolean;
}

const BookingsLayout = styled.div`
  width: 100%;
`;

const ButtonWrapper = styled.div`
  width: calc(100% - 10px);
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const ButtonLabel = styled.div<IButtonStyle>`
  font-size: 1.2em;
  font-weight: 500;
  color: ${config.color.red};
  text-transform: uppercase;
  opacity: ${props => props.disabled ? .2 : 1};
`;

const DaysList = styled.div`
  height: calc(100vh - 140px - 36px - 58px - 90px);;
  overflow: scroll;
`;

const BookingsListContentDef = () => {
  const { t } = useTranslation();
  const [requestingData, setRequestingData] = useState(true);
  const [isLeftHours, setIsLeftHours] = useState(false);
  const [apiError, setApiError] = useState<ApiError>();
  const [availableDays, setAvailableDays] = useState<Date[]>([]);
  const [partnerName, setPartnerName] = useState("")

  useEffect(() => {
    const subscription = bookingsStore.getBookings().subscribe({
      next: (resp) => {
        setRequestingData(false);
        setIsLeftHours(bookingsStore.getIfLeftHours());
        setAvailableDays(bookingsStore.getAvailableDays());
        setPartnerName(bookingsStore.getPartnerName());
      },
      error: (e: ApiError) => {
        setApiError(e);
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  var content: any;
  if (requestingData && !apiError) {
    content = <Loader size="small"/>
  } else {
    content = availableDays.map((day: Date) => {
      return <Day key={new Date(day).getTime()}
                  day={day}
                  />;
    });
  }

  return <BookingsLayout>
    <PartnerName partnerName={partnerName}/>
    <ButtonWrapper>
      <ButtonLabel disabled={!isLeftHours}>{t("label.invite")}</ButtonLabel>
        <ButtonLink
          buttonIcon={"AddCircleLarge"}
          route={"bookings/invite"}
          color={config.color.white}
          backgroundColor={config.color.black}
          size="large"
          disabled={!isLeftHours}
        />
    </ButtonWrapper>
    <DaysList>
      {content}
    </DaysList>
  </BookingsLayout>;
};

export const BookingsList = memo(BookingsListContentDef);