import React, { memo } from "react";
import styled from "styled-components";
import { ButtonLink } from "./button-link";

const ButtonWrapper = styled.div`
  width: 100%;
  text-align: left;
  margin-top: -12px;
`;

const ButtonBackDef = () => {
  return <ButtonWrapper>
      <ButtonLink buttonIcon={"KeyboardArrowLeft"}
                  route={"bookings"}
                  />
    </ButtonWrapper>
};

export const ButtonBack = memo(ButtonBackDef);