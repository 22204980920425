import React, { memo, useState, useEffect } from "react";
import styled from "styled-components";
import { bookingsStore } from "../../stores/bookings.store";

interface IProps {
  partnerName?: string;
}

const PartnerNameWrapper = styled.div`
  font-size: 32px;
  line-height: 36px;
  font-weight: 100;
  text-transform: uppercase;
`;

const PartnerNameDef = (props: IProps) => {

  const partnerName = props.partnerName || bookingsStore.getPartnerName();

  return <PartnerNameWrapper>
    {partnerName}
  </PartnerNameWrapper>;
};

export const PartnerName = memo(PartnerNameDef);