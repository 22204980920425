import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import styled from "styled-components";
import './App.css';
import { ApiErrorCode } from "./services/api-errors";
import { AuthenticatedLayout } from "./components/authenticated-layout/authenticated-layout";
import { UnauthenticatedLayout } from "./components/unauthenticated-layout/unauthenticated-layout";
import { ErrorLayout } from "./components/error-layout/error-layout";
import { Header } from "./components/header/header";
import { Footer } from "./components/footer/footer";

const AppStyle = styled.div`
  width: 100vw;
  max-width: 768px;
  height: 100vh;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

function App() {
  return (
    <AppStyle className="App">
      <Header />
      <Router>
        <Switch>
          <Route path={["/bookings/:token", "/bookings"]}>
            <AuthenticatedLayout />
          </Route>
          <Route path="/invitations/:id">
            <UnauthenticatedLayout />
          </Route>
          <Route>
            <ErrorLayout status={404} code={ApiErrorCode.not_found}/>
          </Route>
        </Switch>
      </Router>
      <Footer />
    </AppStyle>
    );
  }

  export default App;
