import React, { memo, useState,  } from "react";
import styled from "styled-components";
import moment from "moment";
import 'moment/locale/fr';
import { useTranslation } from 'react-i18next';
import { BookingState } from "../../types";
import { config } from "../../config";
import { ButtonSubmit } from "../buttons/button-submit";
import { ErrorBanner } from "../error-layout/error-banner";
import { bookingStore } from "../../stores/booking.store";
import { ApiError } from "../../services/api-errors";
import { putConfirm } from "../../services/booking.service";

const InvitationContent = styled.div`
  width: 90%;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border: .2em solid ${config.color.black};
  margin: 16px;
`;

const Title = styled.div`
  font-size: 1.5em;
  font-weight: 500;
  text-transform: uppercase;
`;

const BookingTime = styled.div`
  font-size: 1.4em;
  font-weight: 700;
  color: ${config.color.red};
  text-transform: capitalize;

  .time {
    font-size: .7em;
    color: ${config.color.black};
    margin-top: 8px;
  }
`;

const PartnerName = styled.div`
  font-size: 1.7em;
  line-height: 1.2em;
  font-weight: 100;
  text-transform: uppercase;
  margin-top: 8px;
`;

const BookingContact = styled.div`
  padding: 8px 0;
  font-size: 1.2em;
  line-height: 1em;
  font-weight: 400;
  color: ${config.color.black} ;
  text-transform: capitalize;
`;

const ConfirmedMessage = styled.div`
  padding: 4px 12px;
  font-size: 1em;
  line-height: 1em;
  font-weight: 400;
  color: ${config.color.red};
  border: .1em solid ${config.color.red};
  text-transform: uppercase;
`;

const InvitationContentDef = () => {
  const { t } = useTranslation();
  const invitation = bookingStore.getInvitation();
  const [validatedState, setValidatedState] = useState((invitation && invitation.validated_state));
  const [apiError, setApiError] = useState<ApiError>();
  const bookingToken: string = document.location.search;

  const partnerName = bookingStore.getPartnerName();

  const handleOnClick = (ev: any) => {
    putConfirm(invitation.id.toString(), bookingToken).subscribe((response) => {
      setValidatedState(response.booking.validated_state);
    }, (error) => {
        setApiError(error.message);
        console.log("err: ", error);
    });
  };

  const handleResetError = () => {
    setApiError(null!);
  }

  const gender = invitation.gender === "2" ? t('form.female') : t('form.male');
  const needConfirmation = invitation.booking_state === BookingState.validated && validatedState !== BookingState.clientConfirmed

  return <InvitationContent>
    {apiError && <ErrorBanner error={apiError} onCloseBanner={handleResetError} />}
    <Title>{t("label.invitation")}</Title>
    <BookingContact>
      {gender} {invitation.contact.firstname} {invitation.contact.lastname}
    </BookingContact>
    <BookingTime>
        {moment(invitation.booking_date).format("dddd") + " " + moment(invitation.booking_date).format("LL")}
        <div className="time">{moment(invitation.booking_time).format("hh") + "h" + moment(invitation.booking_time).format("mm")}</div>
    </BookingTime>
    <div>
      {t("label.from")}
      <PartnerName>
        {partnerName}
      </PartnerName>
    </div>
    {needConfirmation &&
      <ButtonSubmit
          buttonText={"confirmation"}
          onClick={handleOnClick}
          />
      }
    {!needConfirmation &&
      <ConfirmedMessage>{t("button.confirmed")}</ConfirmedMessage>}
  </InvitationContent>
};

export const Invitation = memo(InvitationContentDef);