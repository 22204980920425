import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { config } from "../../config";

interface IErrorLayoutProps {
  status?: number;
  code?: string;
  message?: string
}

const ErrorStyle = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ErrorDescriptionStyle = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  font-size: 1.4em;

  a, a:visited {
    color: ${config.color.red};
    font-size: .6em;
    margin-top: 8px;
  }
`;

const ErrorLayoutDef = (props: IErrorLayoutProps) => {
  const { t } = useTranslation();
  const errorLabel = props.code ? t(`errors.${props.code}`) : props.message;

  return <ErrorStyle>
    <ErrorDescriptionStyle data-test="error-message">
      {errorLabel}
      <a target='_blank' rel="noreferrer" href={"https://www.omnivore.com/"} >
        {t("go_to_sirha_omnivore_site")}
      </a>
    </ErrorDescriptionStyle>
  </ErrorStyle >;
}
export const ErrorLayout = memo(ErrorLayoutDef);
