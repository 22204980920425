import { config } from "../config";

class RequestParamsService {
  private readonly token: string;
  // private token?: string;

  constructor(token: string) {
    if (token && token !== "invite") {
      this.token = token;
      localStorage.setItem('token', token)
    } else {
      this.token = localStorage.getItem('token')!;
    }
  }

  public getParams(): { token: string } {
    return {
      token: this.token
    };
  }

  public setSessionToken(token: string) {
    return localStorage.setItem('token', this.token)
  }
}

let requestParamsService: RequestParamsService;

export const initRequestParamsService = (token: string) => {
  requestParamsService = new RequestParamsService(token);
};

export const getRequestParamsService = (token?: string): RequestParamsService => {
  if (!requestParamsService && token) {
    requestParamsService = new RequestParamsService(token);
  }
  return requestParamsService;
};

export const buildRouteWithParams = (route: string): string => {
  let baseUrl = config.routes.base;
    return `${baseUrl}/${route}`;
}
