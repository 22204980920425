export interface ApiError {
  response?: any;
  status: number;
  code: ApiErrorCode;
  data?: ApiErrorBody;
  message?: string;

  getMessage(): string;
}

export interface ApiErrorBody {
  message: string;
  code?: string;
}

export interface ApiErrorResponse {
  error: ApiErrorBody;
}

export interface AccessApiErrorBody extends ApiErrorBody {
  partner_name: string;
}

export enum ApiErrorCode {
  unknown = "unknown",
  connection_error = "connection_error",
  partner_id_missing = "partner_id_missing",
  partner_blank = "partner_blank",
  no_access = "no_access",
  access_denied = "access_denied",
  partnership_disabled = "partnership_disabled",
  partnership_not_yet_created = "partnership_not_yet_created",
  not_found = "not_found"
}

export interface AccessDeniedError extends ApiError {

}