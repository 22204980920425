import React, { memo, useState, useEffect } from "react";
import styled from "styled-components";
import moment from "moment";
import { useTranslation } from 'react-i18next';
import { Redirect } from "react-router-dom";
import { Radio, RadioGroup, FormControlLabel, TextField } from "@material-ui/core";
import { config } from "../../config";
import { PendingInvite } from "../../types";
import { Loader } from "../loader/loader";
import { ButtonBack } from "../buttons/button-back";
import { PartnerName } from "../partner/partnerName";
import { ButtonSubmit } from "../buttons/button-submit";
import { ErrorBanner } from "../error-layout/error-banner";
import { Tags } from "../tag/tags";
import { postInvite } from "../../services/booking.service";
import { ApiError } from "../../services/api-errors";
import { bookingsStore } from "../../stores/bookings.store";

const InviteFormWrapper = styled.form`
  width: 100%;
  height: calc(100vh - 140px - 66px - 90px);
  display: flex;
  flex-wrap: wrap;
  margin-top: -12px;
  justify-content: center;
  overflow: scroll;
`;

const FormElementPadding = styled.div`
  padding: 0 8px;
  margin-bottom: 8px;
`;

const Field = styled(FormElementPadding)`
  flex: 0 0 80%;
  max-width: 80%;
`;

const CustomErrorField = styled(TextField)`
  .MuiFilledInput-underline:before,
  .MuiFilledInput-underline:after {
    border: none;
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${config.color.black};
  }

  &.error {
    .MuiFilledInput-notchedOutline {
      border-color: ${config.color.red};
    }

    .MuiOutlinedInput-root:not(.Mui-focused) {
      color: ${config.color.red};
    }
  }
`;

const TagsWrapper = styled.div`
  width: 100%;
  margin: 12px 0;
`;

const InviteForm = () => {
  const { t } = useTranslation();
  const [requestingData, setRequestingData] = useState(true);
  const [isLeftHours, setIsLeftHours] = useState(true);
  const [apiError, setApiError] = useState<ApiError>();
  const [redirect, setRedirect] = useState(false);
  const [gender, setGender] = useState("1");
  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState(false);
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [selectedHour, setSelectedHour] = useState("");
  const [selectedHourError, setSelectedHourError] = useState(false)

  useEffect(() => {
    const subscription = bookingsStore.initAvailabilities().subscribe({
      next: (resp) => {
        setIsLeftHours(resp.availabilities.length > 0);
        setRequestingData(false);
        },
      error: (e: ApiError) => {
        setApiError(e);
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const handleOnSelectedHour = (selectedHour: string) => {
    setSelectedHour(selectedHour);
  }

  const handleResetError = () => {
    setApiError(null!);
  }

  const onGenderChange = (ev: any) => {
    updateField(ev.target.value, setGender);
  };
  const onFirstNameChange = (ev: any) => {
    updateField(ev.target.value, setFirstName, setFirstNameError);
  };
  const onLastNameChange = (ev: any) => {
    updateField(ev.target.value, setLastName, setLastNameError);
  };
  const onPhoneChange = (ev: any) => {
    updateField(ev.target.value, setPhone, setPhoneError);
  };

  const checkFormValidity = () => {
    let error = false;
    error = checkFieldValidity(firstName, setFirstNameError, error);
    error = checkFieldValidity(lastName, setLastNameError, error);
    error = checkFieldValidity(phone, setPhoneError, error);
    error = checkFieldValidity(selectedHour.toString(), setSelectedHourError, error);

    if (!error) {
      const bookingTime = moment(selectedHour).toDate();

      const pendingInvite: PendingInvite = {
        invite: {
          booking_time: bookingTime,
          lastname: lastName,
          firstname: firstName,
          mobile_phone: phone,
          gender: gender
        }
      };

      setSelectedHour("");
      postInvite(pendingInvite).subscribe({
        next: () => {
          setRedirect(true);
        },
        error: (error) => {
          setApiError(error.message)
          console.log("err: ", error);
        }
      });
    }
  };

  const checkFieldValidity = (value: string, setError: any, hasError: boolean) => {
    if (!value.length) {
      setError(true);
      return true;
    }
    return hasError;
  };

  const updateField = (value: string, message: any, error?: any) => {
    message(value);
    if (value.length && error) {
      error(false);
    }
  };

  var contentTags: any;
  if (requestingData && !apiError) {
    contentTags = <Loader size="small"/>
  } else if (isLeftHours) {
    contentTags = <Tags onSelectedHour={handleOnSelectedHour} />
  } else {
    contentTags = t("form.no_hour")
  }

  return <div>
    {apiError && <ErrorBanner error={apiError} onCloseBanner={handleResetError} />}
    {redirect && <Redirect to={"/bookings"} />}
    <PartnerName />
    <ButtonBack />
    <InviteFormWrapper>
      <RadioGroup row aria-label="gender" value={gender} name="gender" onChange={onGenderChange}>
        <FormControlLabel
          value="2"
          control={<Radio />}
          label={t("form.female")} />
        <FormControlLabel
          value="1"
          control={<Radio />}
          label={t("form.male")} />
      </RadioGroup>
      <Field>
        <CustomErrorField
          onChange={onFirstNameChange}
          className={firstNameError ? "error" : ""}
          required={true}
          fullWidth={true}
          size={"small"}
          label={t("form.firstname")}
          variant={"filled"}/>
      </Field>
      <Field>
        <CustomErrorField
          onChange={onLastNameChange}
          className={lastNameError ? "error" : ""}
          required={true}
          fullWidth={true}
          size={"small"}
          label={t("form.lastname")}
          variant={"filled"}/>
      </Field>
      <Field>
        <CustomErrorField
          onChange={onPhoneChange}
          className={phoneError ? "error" : ""}
          required={true}
          fullWidth={true}
          size={"small"}
          label={t("form.phone")}
          variant={"filled"}/>
        </Field>
        <TagsWrapper>
          {contentTags}
        </TagsWrapper>
        <ButtonSubmit buttonText={"invite"}
                      disabled={!firstName || firstNameError
                        || !lastName || lastNameError
                        || !phone || phoneError
                        || !selectedHour || selectedHourError}
                      onClick={checkFormValidity}
                      />
    </InviteFormWrapper>
  </div>
};

export const Invite = memo(InviteForm);