import React, { memo } from "react";
import styled from "styled-components";
import powerByGuestonline from '../../assets/images/powerByGuestonline.png'

const FooterWrapper = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
  img {
    width: 50%;
    max-width: 220px;
  }
`;

const FooterDef = () => {
  return <FooterWrapper>
    <a
      className="App-link"
      href="https://www.guestonline.io/"
      target="_blank"
      rel="noopener noreferrer"
    >
    <img src={powerByGuestonline} alt="PowerByGuestonline" />
  </a>
  </FooterWrapper>;
};

export const Footer = memo(FooterDef);