import React, { memo } from "react";
import styled from "styled-components";
import moment from 'moment';
import { config } from "../../config";
import { bookingsStore } from "../../stores/bookings.store";
import { Booking, BookingState } from "../../types";
import { Booking as BookingView } from "./booking";

interface IProps {
  time: Date;
}

interface IPropsStyle {
  color?: string;
  background?: string;
}

const HourWrapper = styled.div`
  margin-bottom: 12px;
`;

const HourContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.8em;
  font-weight: 500;
  line-height: 0.8em;
  border-bottom: 1px solid ${config.color.red};
  `;

const BookingsCount = styled.div<IPropsStyle>`
  padding: 2px 2px 4px;
  color: ${props => props.color ? props.color : config.color.black};
  background-color: ${props => props.background ? props.background : config.color.white};
  margin-bottom: 2px;
`;

const BookingsCounts = styled.div`
  display: flex;
`;

const Time = styled.div`
  font-weight: 700;
  margin-bottom: 4px;
`;

const HourDef = (props: IProps) => {

  const validatedBookings: Array<Booking> = bookingsStore.getBookingsOfTime(props.time, BookingState.validated)
  const waitingBookings: Array<Booking> = bookingsStore.getBookingsOfTime(props.time, BookingState.waiting)

  const bookingsView = validatedBookings.map((booking: Booking, index: number) => {
    return <BookingView key={index}
                        booking={booking}
                        />
  });

  return <HourWrapper>
    <HourContent>
      <BookingsCounts>
        <BookingsCount color={config.color.white} background={config.color.red}>
          {validatedBookings.length}
        </BookingsCount>
        <BookingsCount>
          / {validatedBookings.length+waitingBookings.length}
        </BookingsCount>
      </BookingsCounts>
      <Time>{moment(props.time).format("HH:mm")}</Time>
    </HourContent>
    {bookingsView}
  </HourWrapper>
};

export const Hour = memo(HourDef);