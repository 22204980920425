import React, { memo } from "react";
import styled from "styled-components";
import { KeyboardArrowUp, KeyboardArrowDown, KeyboardArrowLeft, KeyboardArrowRight, AddCircle  } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { config } from "../../config";
import { buildRouteWithParams } from "../../services/request-params.service";

interface IProps {
  buttonIcon: string;
  route: string;
  color?: string;
  backgroundColor?: string;
  size?: string;
  disabled?: boolean;
}
interface IButtonStyle {
  color?: string;
  backgroundcolor?: string;
  size?: string;
  disabled? : boolean;
}

const ButtonWrapper = styled.div`
  margin: 8px 0px 8px 8px;
`;

const ButtonLinkStyle = styled(Link)<IButtonStyle>`
  pointer-events: ${props => props.disabled ? "none" : "pointer"};
  color: ${props => props.color ? props.color : config.color.black};
  background-color: ${props => props.backgroundcolor ? props.backgroundcolor : config.color.white};
  opacity: ${props => props.disabled ? .2 : 1};

  svg {
    margin-top: 4px;
  }
`;

const renderIcon = (icon: string) => {
  switch(icon) {
    case "AddCircleLarge":
        return <AddCircle fontSize="large" />;
    case "KeyboardArrowLeft":
      return <KeyboardArrowLeft fontSize="large" />;
    case "KeyboardArrowRight":
      return <KeyboardArrowRight />;
    case "KeyboardArrowUp":
        return <KeyboardArrowUp />;
    case "KeyboardArrowDown":
          return <KeyboardArrowDown />;
    default:
      return <AddCircle />;
  }
}

const ButtonLinkDef = (props: IProps) => {
  return <ButtonWrapper>
    <ButtonLinkStyle disabled={props.disabled} to={buildRouteWithParams(props.route)}>
      {renderIcon(props.buttonIcon)}
    </ButtonLinkStyle>
  </ButtonWrapper>
};

export const ButtonLink = memo(ButtonLinkDef);