import React, { memo } from "react";
import styled from "styled-components";
import { HighlightOff }from '@material-ui/icons';
import { config } from "../../config";
// import { useTranslation } from 'react-i18next';
import { ApiError } from "../../services/api-errors";

interface IProps {
  error: ApiError;
  onCloseBanner: any;
}

const BannerWrapper = styled.div`
  position: absolute;
  top: 20%;
  left: 0;
  width: 100%;
  color: ${config.color.white};
  background-color: ${config.color.red};
  padding: 12px 0;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 8px;
  right: 16px;
`;

const ErrorBannerDef = (props: IProps) => {
  // const { t } = useTranslation();

  return <BannerWrapper>
    {props.error}
    <CloseButton onClick={props.onCloseBanner}>
      <HighlightOff />
    </CloseButton>
  </BannerWrapper>
};

export const ErrorBanner = memo(ErrorBannerDef);