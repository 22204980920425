import axios from "axios";
import { config } from "../config";
import { from, Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { QueryParam } from "../types";
import { getRequestParamsService } from "./request-params.service";

class HttpService {
  public url: string;
  public queryParams?: Array<QueryParam> | null;

  constructor(url: string, queryParams?: Array<QueryParam>) {
      this.url = url;
      this.queryParams = queryParams ?? null;
  }

  public static getHeader(): any  {
    const requestParamsService = getRequestParamsService();
    const { token } = requestParamsService.getParams();
    return {
      "X-Token": token,
      "Content-Type": "application/json"
    };
  }

  public static setQueryParams(url: string, queryParams: Array<QueryParam>) {
    if (queryParams && queryParams.length !== 0) {
      url += "?" + queryParams.map((queryParam: QueryParam) => `${queryParam.queryParamName}=${queryParam.queryParamValue}`).join(("&"));
    }
    return url
  }

  public static get(url: string, hasHeaders?: boolean): Observable<any> {
    const baseUrl = config.baseUrl;
    const headers = hasHeaders ? HttpService.getHeader() : {};

    return from(axios({
      method: "GET",
      url: `${baseUrl}/${url}`,
      headers: headers
    })).pipe(
      map((resp) => {
        return resp.data;
      }),
      catchError((err) => {
        return new Observable((observer) => {
          observer.error(err);
          observer.complete();
        });
      }),
    );
  }

  public static post(url: string, data?: any): Observable<any> {
    const baseUrl = config.baseUrl;
    const headers = HttpService.getHeader();

    return from(axios({
      method: "POST",
      url: `${baseUrl}/${url}`,
      headers: headers,
      data
    })).pipe(
      map((resp) => {
        return resp.data;
      }),
      catchError((err) => {
        return new Observable((observer) => {
          observer.error(err);
          observer.complete();
        });
      }),
    );
  }

  public static put(url: string, data?: any): Observable<any> {
    const baseUrl = config.baseUrl;

    return from(axios({
      method: "PUT",
      url: `${baseUrl}/${url}`,
      data
    })).pipe(
      map((resp) => {
        return resp.data;
      }),
      catchError((err) => {
        return new Observable((observer) => {
          observer.error(err);
          observer.complete();
        });
      }),
    );
  }
}

export default HttpService;