import React, { memo } from "react";
import styled, { keyframes } from "styled-components";

interface IProps {
  size?: string;
}

interface ILoaderStyle {
  size?: string;
}

const spinner = keyframes`
  from {
    transform: rotate(0deg);
 }

  to {
    transform: rotate(360deg);
 }
`;

const LoaderSpinner = styled.div<ILoaderStyle>`
	border: ${props => props.size === "small" ? "5px" : "20px"} solid #f3f3f3;
	border-top: ${props => props.size === "small" ? "5px" : "20px"}  solid #dc2c39;
	border-radius: 50%;
	width: ${props => props.size === "small" ? "50px" : "200px"};
	height: ${props => props.size === "small" ? "50px" : "200px"};
	animation: ${spinner} 2s linear infinite;
`;

const LoaderSpinnerContainer = styled.div<ILoaderStyle>`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const LoaderDef = (props: IProps) => {
    return <LoaderSpinnerContainer>
        <LoaderSpinner size={props.size}/>
    </LoaderSpinnerContainer>;
};
export const Loader = memo(LoaderDef);
