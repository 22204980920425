import React, { memo } from "react";
import styled from "styled-components";
import { config } from "../../config";

interface IProps {
  value: any;
  tagText: string;
  isSelected?: boolean;
  onClick: any;
}

interface ITagStyle {
  isSelected?: boolean;
}

const TagBox = styled.div<ITagStyle>`
  width: fit-content;
  background: lightgray;
  padding: 4px 12px;
  margin: 4px;
  border: 1px solid ${props => props.isSelected ? `${config.color.red}` : `${config.color.white}`};
`;

const TagDef = (props: IProps) => {
  return <TagBox
    data-value={props.value}
    isSelected={props.isSelected}
    onClick={props.onClick}
    >
    {props.tagText}
  </TagBox>
};

export const Tag = memo(TagDef);