import React, { memo } from "react";
import styled from "styled-components";
import { Check } from "@material-ui/icons";
import { config } from "../../config";
import { BookingState } from "../../types";

interface IProps {
  booking: any;
}

const BookingWrapper = styled.div`
  display: flex;
  align-self: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid lightgray;

  .MuiSvgIcon-root {
    margin-top: 4px;
  }
`;

const BookingContact = styled.div`
  padding: 8px 0;
  font-size: .9em;
  line-height: 1em;
  font-weight: 500;
  color: ${config.color.black} ;
  text-transform: capitalize;
  text-align: left;

`;

const ContactPhone = styled.div`
  font-size: .8em;
  margin-top: 4px;
`;

const BookingDef = (props: IProps) => {
  const isClientConfirmed: boolean = props.booking.validated_state === BookingState.clientConfirmed;
  const mobile_phone: string = `+${props.booking.contact.mobile_phone.substring(0, 2)}
                                ${props.booking.contact.mobile_phone.substring(2, 3)}
                                ${props.booking.contact.mobile_phone.substring(3, 5)}
                                ${props.booking.contact.mobile_phone.substring(5, 7)}
                                ${props.booking.contact.mobile_phone.substring(7, 9)}
                                ${props.booking.contact.mobile_phone.substring(9, props.booking.contact.mobile_phone.length)}`;

  return <BookingWrapper>
      <BookingContact>
        <div>{props.booking.contact.firstname} {props.booking.contact.lastname}</div>
        <ContactPhone>{mobile_phone}</ContactPhone>
      </BookingContact>
      {isClientConfirmed && <Check fontSize={"small"}/>}
  </BookingWrapper>;
};

export const Booking = memo(BookingDef);