export enum BookingState {
  initialized = "initialized",
  waiting = "waiting",
  validated = "validated",
  canceled = "canceled",
  clientConfirmed = 'client_confirmed'
}

export interface BaseInterface {
  object: string;
}

export interface Availability extends BaseInterface {
  date: string;
  hours: Array<string>;
}

export interface Availabilities extends BaseInterface {
  availabilities: Array<Availability>
}

export interface Day extends BaseInterface {
  date: Date;
}

export interface Hour extends BaseInterface {
  time: Date;
}

export interface Hours extends BaseInterface {
  hours: Array<Hour>;
}


export interface Booking extends BaseInterface {
  id: number;
  booking_state: BookingState;
  booking_date: Date;
  booking_time: Date;
  booked_persons_number: number;
  gender: string;
  validated_state: string;
  contact: {
    lastname: string;
    firstname: string;
    phone: string;
    user_locale: string;
  }
}

export interface PendingInvite {
  invite: {
    booking_time: Date;
    lastname: string;
    firstname: string;
    mobile_phone: string;
    gender: string;
  }
}

export interface Bookings extends BaseInterface {
  lenght: number;
  map(arg0: (booking: any) => any): Iterable<unknown> | null | undefined;
  bookings: Array<Booking>;
}

export interface QueryParam {
  queryParamName: string;
  queryParamValue: string;
}

export interface Partner extends BaseInterface {
  id: string;
  name: string;
}