import React, { memo } from "react";
import styled from "styled-components";
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import { BookingsList } from "./bookings-list";
import { Invite } from "./invite";

const BookingsContentFlex = styled.div`
  width: 100%;
`;

const BookingsContentDef = () => {
  return <BookingsContentFlex>
    <Router>
      <Redirect to={"/bookings"}/>
      <Switch>
        <Route exact path="/bookings">
          <BookingsList />
        </Route>
        <Route path="/bookings/invite">
          <Invite />
        </Route>
      </Switch>
    </Router>
  </BookingsContentFlex>;
};

export const BookingsContent = memo(BookingsContentDef);