import { Observable } from "rxjs";
import HttpService from "./http.service";
import { PendingInvite } from "../types";

export const getBooking = (id: string, bookingToken: string): Observable<any> => {
  return HttpService.get(`invitations/${id}${bookingToken}`);
};

export const postInvite = (pendingInvite: PendingInvite): Observable<any> => {
  return HttpService.post("bookings/invite", pendingInvite);
};

export const putConfirm = (id: string, bookingToken: string): Observable<any> => {
  return HttpService.put(`invitations/${id}/confirm${bookingToken}`);
};
