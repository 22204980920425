import React, { memo, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import 'moment/locale/fr';
import { config } from "../../config"
import { Hour } from "./hour"
import { bookingsStore } from "../../stores/bookings.store";
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

interface IProps {
  day: Date;
}

interface IDisplayHour {
  time: Date;
}

interface IButtonStyle {
  isOpen: Boolean;
}

const DaysWrapper = styled.div`
  border-top: .1em solid gray;
  font-size: 1.1em;
  line-height: 1.4em;
  font-weight: 700;
  color: ${config.color.black};
  text-transform: uppercase;
  text-align: left;
  margin: 4px 16px 8px;
`;

const DayContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ButtonList = styled.div<IButtonStyle>`
  cursor: pointer;
  color: ${props => props.isOpen ? config.color.red : config.color.black};
`;

const HoursContent = styled.div<IButtonStyle>`
  cursor: pointer;
  height: ${props => props.isOpen ? "auto" : 0};
  opacity: ${props => props.isOpen ? 1 : 0};
  overflow: hidden;
  transition: all 1s ease-in-out;
`;

const DayDef = (props: IProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const availableHours: Array<IDisplayHour> = [];
  bookingsStore.getAvailableHours(props.day).forEach((hour: Date) => {
    availableHours.push({
      time: hour
    });
  });

  const hoursView = availableHours.map((displayHour: IDisplayHour) => {
    return <Hour
      key={new Date(displayHour.time).getTime()}
      time={displayHour.time}
      />;
  });

  moment.locale("fr")

  return <DaysWrapper>
    <DayContent>
      {moment(props.day).format('dddd') + " " + moment(props.day).format('LL') }
      <ButtonList isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon/>}
      </ButtonList>
    </DayContent>
    <HoursContent isOpen={isOpen}>
      {hoursView}
    </HoursContent>
  </DaysWrapper>;
};

export const Day = memo(DayDef);